import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import CoursePreview from "../components/CoursePreview";
import Tabs from "components/dashboard/TeacherDashboard/components/molecules/Tabs/Tabs";

const Content = ({ data = [], title }) => {
  console.log("in unites", data.length);
  return (
    <div className="posts">
      {data.length > 0 ? (
        (data.length + Math.floor(data.length / 5) + 1) % 2 === 0 ? (
          data.map(({ course }, index) => {
            console.log("🚀 ~ file: unites.js:38 ~ data.map ~ course:", course);
            return (
              <>
                {/* {index % 5 === 0 ? <ListingAds /> : null} */}
                <CoursePreview
                  key={index}
                  course={{
                    title: course.frontmatter.title,
                    slug: course.fields.slug,
                    fluid: {
                      ...course.frontmatter.thumbnail.childImageSharp.fluid,
                      originalName: course.frontmatter.thumbnail.childImageSharp.fluid?.originalName?.replace(
                        /Banner|.png/g,
                        ""
                      ),
                    },
                    description:
                      course.frontmatter.description || course.excerpt,
                  }}
                  loading={index > 3 ? "eager" : "lazy"}
                />
              </>
            );
          })
        ) : (
          [
            ...data.map(({ course }, index) => {
              console.log(
                "🚀 ~ file: unites.js:38 ~ data.map ~ course:",
                course
              );
              return (
                <>
                  {/* {index % 5 === 0 ? <ListingAds /> : null} */}
                  <CoursePreview
                    key={index}
                    course={{
                      title: course.frontmatter.title,
                      slug: course.fields.slug,
                      fluid: {
                        ...course.frontmatter.thumbnail.childImageSharp.fluid,
                        originalName: course.frontmatter.thumbnail.childImageSharp.fluid?.originalName?.replace(
                          /Banner|.png/g,
                          ""
                        ),
                      },
                      description:
                        course.frontmatter.description || course.excerpt,
                    }}
                    loading={index > 3 ? "eager" : "lazy"}
                  />
                </>
              );
            }),
            <div key={data.length}></div>,
          ]
        )
      ) : (
        <p>
          سوف يتم إضافة
          {` ال${title} `}
          قريبا
        </p>
      )}
    </div>
  );
};

const Unites = ({
  data: {
    courses: { nodes: coursesData },
    exercises: { nodes: exercisesData },
    series: { nodes: seriesData },
  },
  pageContext: { level, division },
  location,
}) => {
  const tabs = [
    {
      name: "دروس",
      value: "courses",
      component: Content,
      args: { data: coursesData, title: "دروس" },
    },
    {
      name: "تمارين",
      value: "exercises",
      component: Content,
      args: { data: exercisesData, title: "تمارين" },
    },
    {
      name: "سلاسل",
      value: "series",
      component: Content,
      args: { data: seriesData, title: "سلاسل" },
    },
  ];
  return (
    <Layout title={`${level} ${division}`} location={location}>
      <Tabs tabs={tabs} style={{ fontSize: "1.5em" }} location={location} />
    </Layout>
  );
};

export default Unites;

export const query = graphql`
  query($level: String!, $division: String!) {
    courses: allFile(
      filter: {
        sourceInstanceName: { eq: "courses" }
        childMdx: {
          frontmatter: {
            level: { eq: $level }
            division: { eq: $division }
            lessonType: { eq: "درس" }
          }
        }
      }
      sort: { fields: childMdx___frontmatter___rank }
    ) {
      nodes {
        course: childMdx {
          fields {
            slug
          }
          frontmatter {
            title
            description
            thumbnail {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
          excerpt(truncate: true)
        }
      }
    }
    exercises: allFile(
      filter: {
        sourceInstanceName: { eq: "exercises" }
        childMdx: {
          frontmatter: {
            level: { eq: $level }
            division: { eq: $division }
            lessonType: { eq: "تمرين" }
          }
        }
      }
      sort: { fields: childMdx___frontmatter___rank }
    ) {
      nodes {
        course: childMdx {
          fields {
            slug
          }
          frontmatter {
            title
            description
            thumbnail {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
          excerpt(truncate: true)
        }
      }
    }
    series: allFile(
      filter: {
        sourceInstanceName: { eq: "series" }
        childMdx: {
          frontmatter: {
            level: { eq: $level }
            division: { eq: $division }
            lessonType: { eq: "سلسلة" }
          }
        }
      }
      sort: { fields: childMdx___frontmatter___rank }
    ) {
      nodes {
        course: childMdx {
          fields {
            slug
          }
          frontmatter {
            title
            description
            thumbnail {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
          excerpt(truncate: true)
        }
      }
    }
  }
`;
